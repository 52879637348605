<template>
	<div class="reporting">
		<div style="display: flex;">
			<Headbuttom></Headbuttom>
			<!-- <el-button type="primary" style="margin-left:20px" @click="onPlus" size="small">
             <i class="el-icon-plus" />新增
            </el-button> -->
			<div @click="handFromshow">
				<el-input size="small" class="dev_dowm" placeholder="" suffix-icon="el-icon-arrow-down"
					:readonly="true">
				</el-input>
			</div>
			<div class="qrBtn" @click="generateQRLoop">生成就餐二维码</div>
		</div>

		<div class="attheadfrom" v-if="flag">
			<el-form :inline="true" size="small" :model="searchParams" class="demo-form-inline">
				<el-form-item label="姓名">
					<el-input v-model="searchParams.name"></el-input>
				</el-form-item>

				<el-form-item label="部门">
					<Select @handleCheckChange="handleCheckChange"></Select>
				</el-form-item>

				<el-form-item label="餐具编号">
					<el-input v-model="searchParams.table_number"></el-input>
				</el-form-item>

				<el-form-item label="工号">
					<el-input v-model="searchParams.number"></el-input>
				</el-form-item>
				<el-form-item label="手机号码">
					<el-input v-model="searchParams.mobile"></el-input>
				</el-form-item>

				<el-form-item label="条码编号">
					<el-input v-model="searchParams.code"></el-input>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="onSubmit">{{
                $t("devtable.cx")
              }}</el-button>
				</el-form-item>
			</el-form>
		</div>

		<!-- 表格 -->
		<div class="tabls">
			<el-table border :data="tableData" style="width: 100%">
				<el-table-column prop="user_id" label="注册ID" align="center" width="80">
				</el-table-column>
				<el-table-column prop="name" align="center" label="姓名" width="100">
				</el-table-column>
				<el-table-column prop="number" label="工号" align="center" width="100">
				</el-table-column>

				<el-table-column prop="dept_name" label="企业部门" align="center" width="100">
				</el-table-column>

				<el-table-column prop="mobile" label="手机号码" align="center" width="150">
				</el-table-column>

				<el-table-column prop="table_number" align="center" label="餐具编号">
					<template slot-scope="scope">

						<el-input size="small" v-if="scope.row.user_id==showt && show" v-model="scope.row.table_number"
							placeholder="请输入内容"></el-input>
						<span v-else>{{scope.row.table_number}}</span>
					</template>
				</el-table-column>

				<el-table-column prop="put_number" align="center" label="摆放货架编号">
					<template slot-scope="scope">
						<el-input size="small" v-if="scope.row.user_id==showt && show" v-model="scope.row.put_number"
							placeholder="请输入内容"></el-input>
						<span v-else>{{scope.row.put_number}}</span>
					</template>
				</el-table-column>

				<el-table-column prop="layers" align="center" label="货架层号">
					<template slot-scope="scope">
						<el-input size="small" v-if="scope.row.user_id==showt && show" v-model="scope.row.layers"
							placeholder="请输入内容"></el-input>
						<span v-else>{{scope.row.layers}}</span>
					</template>
				</el-table-column>

				<el-table-column prop="code" align="center" label="条码编号">
					<template slot-scope="scope">
						<el-input size="small" v-if="scope.row.user_id==showt && show" v-model="scope.row.code"
							placeholder="请输入内容"></el-input>
						<span v-else>{{scope.row.code}}</span>
					</template>
				</el-table-column>


				<el-table-column align="center" label="操作" width="180">
					<template slot-scope="scope">

						<div v-if="!show || scope.row.user_id != showt">
							<el-button @click="handleClick(scope.row)" size="medium">修改</el-button>
						</div>
						<div v-if="show && scope.row.user_id == showt">
							<el-button @click="handleKeep(scope.row)" size="medium">保存</el-button>
							<el-button @click="handleNoke(scope.row)" size="medium">取消</el-button>
						</div>
					</template>

				</el-table-column>
			</el-table>

			<div style="margin-top:20px">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="1"
					:page-sizes="[10, 20, 30, 40]" :page-size="page_size"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		
		<div class="modal" v-show="qrShow"></div>
		<div class="canvasPop" v-show="qrShow">
			<div class="closePop" @click="qrShow=false">X</div>
			<div class="canvasBox" v-for="item in tableData">
				<canvas :id="'bigCanvas'+item.user_id" width="220" height="320" style="border: 1px solid #333;"></canvas>
				<div :id="'qrcode'+item.user_id" style="width:200px; height:200px; margin-top:15px;display: none;" />
			</div>
			
		</div>
		
	</div>
</template>

<script>
	import Select from '@/components/selectree/wltreeselect'
	import QR from '@/api/qrcode.js'
	import QRCode from '@/api/qrcode_new.js'
	export default {
		components: {
			Select,
		},
		data() {
			return {
				flag: false,
				searchParams: {
					name: '',
					number: '',
					mobile: '',
					table_number: '',
					code: '',
					dept_id: '',
				},
				total: null,
				page_size: 10,
				page_index: 1,
				UserInfo: JSON.parse(window.localStorage.getItem('user')),
				tableData: [],
				show: false,
				showt: '',
				qrtop : 100,
				qrwidth : 650,
				qrShow:false,
				qrList:[], // 二维码列表
			}
		},
		mounted() {
			this.handleTablewr()
		},
		methods: {
			generateQRLoop(){
				this.qrList = []
				this.qrList = this.tableData
				console.log(this.qrList);
				for (var i = 0; i < this.qrList.length; i++) {
					this.generateQR(this.qrList[i].user_id,this.qrList[i].name,this.qrList[i].dept_name,this.qrList[i].table_number)
				}
			},
			// 生成二维码
			generateQR(id,name,dept_name,table_number){
				// console.log(id)
				this.qrShow=true
				// console.log('333');
				// let w = 375;
				// let h = 642;
				let timestamp = Math.round(new Date().getTime()/1000);
				let timeEnd = Math.round(new Date('2033-3-18').getTime()/1000);
				console.log(timeEnd)
				console.log('star',timestamp);
				let qrcodeTxt = this.buildUserQRcode(id, timeEnd, timeEnd);
				// let qrcodeTxt = this.buildUserQRcode(1, 2, 3);
				// let scale = w / 750; 
				// this.qrtop = (h - this.qrwidth * scale) / 2;
				console.log('123',qrcodeTxt);
				// this.qrwidth * scale,this.qrwidth * scale
				var qrcode = new QRCode(document.getElementById("qrcode"+id), {
					// width : 200,
					// height : 200,
					width : 180,
					height : 180
				});
				qrcode.makeCode(qrcodeTxt,id);
				
				var bigCanvasCtx=document.getElementById('bigCanvas'+id).getContext("2d");
				var img = document.getElementById("erweima"+id);
				// console.log(img.src);
				if(img.src){
					bigCanvasCtx.fillStyle="#ffffff"; 
					bigCanvasCtx.fillRect(0,0,250,350);
					bigCanvasCtx.textAlign="center";
					bigCanvasCtx.font="20px Arial";
					bigCanvasCtx.fillStyle = 'red';
					// console.log(name);
					// bigCanvasCtx.fillText(name,125,50);
					bigCanvasCtx.fillText(name,115,35);
					// bigCanvasCtx.fillText('部门：'+dept_name,125,310);
					bigCanvasCtx.fillText('部门：'+dept_name,110,240);
					// bigCanvasCtx.fillText('餐具编号：'+table_number,125,340);
					bigCanvasCtx.fillText('餐具编号：'+table_number,110,270);
					// bigCanvasCtx.drawImage(img,25,80,200,200);
					bigCanvasCtx.drawImage(img,30,50,160,160);
				}else{
					img.onload = (()=>{
						bigCanvasCtx.fillStyle="#ffffff"; 
						bigCanvasCtx.fillRect(0,0,250,350);
						bigCanvasCtx.textAlign="center";
						bigCanvasCtx.font="20px Arial";
						bigCanvasCtx.fillStyle = 'red';
						// console.log(name);
						// bigCanvasCtx.fillText(name,125,50);
						bigCanvasCtx.fillText(name,115,35);
						// bigCanvasCtx.fillText('部门：'+dept_name,125,310);
						bigCanvasCtx.fillText('部门：'+dept_name,110,240);
						// bigCanvasCtx.fillText('餐具编号：'+table_number,125,340);
						bigCanvasCtx.fillText('餐具编号：'+table_number,110,270);
						// bigCanvasCtx.drawImage(img,25,80,200,200);
						bigCanvasCtx.drawImage(img,30,50,160,160);
					})
				}
				// bigCanvasCtx.clearRect(0,0,250,350);
				
				
				// QR.qrApi.draw(qrcode,"qrcode",50,20,this.qrwidth * scale, this.qrwidth * scale);
			},
			buildUserQRcode(userId,index,timestamp){
			  var bytes = new Uint8Array(12);
			  bytes[0] = ((userId) & 0xFF);
			  bytes[1] = ((userId >> 8) & 0xFF);
			  bytes[2] = ((userId >> 16) & 0xFF);
			  bytes[3] = ((userId >> 24) & 0xFF);
			  bytes[4] = ((index) & 0xFF);
			  bytes[5] = ((index >> 8) & 0xFF);
			  bytes[6] = ((index >> 16) & 0xFF);
			  bytes[7] = ((index >> 24) & 0xFF);
			  bytes[8] = ((timestamp) & 0xFF);
			  bytes[9] = ((timestamp >> 8) & 0xFF);
			  bytes[10] = ((timestamp >> 16) & 0xFF);
			  bytes[11] = ((timestamp >> 24) & 0xFF);
			  return "M" + this.translateArrayBufferToBase64(bytes);
			},
			translateArrayBufferToBase64(buffer){
			    let binaryStr = "";
			    const bytes = new Uint8Array(buffer);
			    for(let i=0;i<bytes.length;i++){
			        binaryStr +=String.fromCharCode(bytes [i]);    
			    }
			    return window.btoa(binaryStr );
			},
			// 餐具列表
			handleTablewr() {
				let data = {
					method: 'co.dinner.tableware.list',
					page_index: this.page_index,
					page_size: this.page_size,
					agent_id: this.UserInfo.agent_id,
					dept_id: this.searchParams.dept_id,
					is_dinner_sys: this.UserInfo.is_dinner_sys,
					enterprise_id: '0',
					name: this.searchParams.name,
					mobile: this.searchParams.mobile,
					number: this.searchParams.number,
					table_number: this.searchParams.table_number,
					code: this.searchParams.code
				}
				this.$serve(data).then(res => {
					if (res.data.code == 0) {
						this.tableData = res.data.data
						this.total = res.data.count
						// this.tableData=[
						// 	{
						// 		user_id:9520,
						// 		name:'胡胡',
						// 		dept_name:'研发部',
						// 		table_number:8890
						// 	},{
						// 		user_id:9521,
						// 		name:'胡胡',
						// 		dept_name:'研发部',
						// 		table_number:8890
						// 	},{
						// 		user_id:9522,
						// 		name:'胡胡',
						// 		dept_name:'研发部',
						// 		table_number:8890
						// 	},{
						// 		user_id:9523,
						// 		name:'胡胡',
						// 		dept_name:'研发部',
						// 		table_number:8890
						// 	},{
						// 		user_id:9524,
						// 		name:'胡胡',
						// 		dept_name:'研发部',
						// 		table_number:8890
						// 	},{
						// 		user_id:9525,
						// 		name:'胡胡',
						// 		dept_name:'研发部',
						// 		table_number:8890
						// 	},{
						// 		user_id:9526,
						// 		name:'胡胡',
						// 		dept_name:'研发部',
						// 		table_number:8890
						// 	},{
						// 		user_id:9527,
						// 		name:'胡胡',
						// 		dept_name:'研发部',
						// 		table_number:8890
						// 	},{
						// 		user_id:9528,
						// 		name:'胡胡',
						// 		dept_name:'研发部',
						// 		table_number:8890
						// 	},{
						// 		user_id:9529,
						// 		name:'胡胡',
						// 		dept_name:'研发部',
						// 		table_number:8890
						// 	}
							
						// ]
					}
				})
			},
			handleClick(row) {
				this.showt = row.user_id
				this.show = true
			},
			// 保存
			handleKeep(row) {
				if (row.user_id == this.showt) {
					let data = {
						method: 'co.dinner.tableware.update',
						id: row.id,
						code: row.code,
						layers: row.layers,
						put_number: row.put_number,
						table_number: row.table_number
					}
					this.$serve(data).then(res => {
						if (res.data.code == 0) {
							this.$message({
								message: "保存成功!",
								type: "success",
							});
							this.show = false
						} else {
							this.$message({
								message: "保存失败!",
								type: "error",
							});
						}
					})
				}


			},
			//取消
			handleNoke() {
				this.show = false
			},

			handFromshow() {
				this.flag = !this.flag
			},
			onPlus() {
				this.$refs.tablewardlog.dialogVisible = true
				this.$refs.tablewardlog.handEmplya()
			},
			// 获取企业部门id
			handleCheckChange(val) {
				let dept_id = []
				val.forEach(el => {
					dept_id.push(el.id)
				})
				this.searchParams.dept_id = dept_id.join(",");
				console.log(`677`, this.searchParams.dept_id)
			},

			// 分页

			handleCurrentChange(v) {
				this.page_index = v
				this.handleTablewr()
			},
			handleSizeChange(v) {
				this.page_size = v
				this.handleTablewr()
			},
			onSubmit() {
				this.handleTablewr()
			}
		}
	}
</script>

<style lang="less" scoped>
	.reporting {
		padding: 20px;
		background-color: #fff;
	}

	.dev_dowm {
		margin-left: 30px;
	}

	.attheadfrom {
		padding: 20px;
		margin-top: 10px;
	}

	.tabls {
		padding: 20px;
	}
	.qrBtn{
		padding: 9px 15px;
		font-size: 12px;
		border-radius: 3px;
		color: #FFF;
		background-color: #409EFF;
		border-color: #409EFF;
		margin-left: 50px;
	}
	.modal{
		position: fixed;
		top: 0;
		left: 0;
		background-color: #000;
		opacity: 0.3;
		width: 100%;
		height: 100%;
	}
	.closePop{
		position: absolute;
		right: 0px;
		top: 200px;
		background-color: #000;
		height: 30px;
		width: 31px;
		color: #fff;
		text-align: center;
		line-height: 30px;
	}
	.canvasPop{
		background-color: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		padding-right: 20px;
		width: 90%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}
	.canvasBox{
		margin-right: 10px;
	}
</style>
